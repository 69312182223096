import React from 'react';
import { Link } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo/seo";

import '../components/lists/asset-list.scss';
import Back from '../components/back/back';

const GovFinancialApp = () => {
  return (
    <Layout>
      <div className="details case-study">
        <SEO title=".Gov Website Redesign" />

        <div className="fixed-nav desktop-only">
          <a href="/">Home</a>
        </div>

        <section className="wrapper-container">
          <div className="mobile-only">
            <a href="/">Home</a>
          </div>

          <h3>.Gov Financial Application Redesign</h3>

          <p>
          Provided wireframes and medium-fi designs for pre-determined sections of a legacy financial application while participating in a successful effort to further design efforts as a UI/UX designer and consultant.
          </p>

          <h3>Analysis & Wireframing</h3>
          <p>Provided wireframes for initial requirements pertaining to reporting, landing page, and administration. Utilized customer support queries, weighted priority lists from stakeholders, and user and stakeholder interviews.</p>

          <p>
          Prioritized tablet and non-fullscreen resolutions to promote RWD. Future usage would require tablet/kiosk usage while reporting usage was often used in conjunction with other applications.
          </p>

          <p>
          During the analysis of research, requirements, and heuristics of the application, three main things were noticed.
          </p>

          <ul className="bullet-list">
            <li>Main priorities were management-focused</li>
            <li>Critical performance hindering experiences related to both non-management and management personnel.</li>
            <li>Personas did not align with development versus requirements priorities.</li>
          </ul>

          <p>
          While providing wireframes and their iterations, began the process of promoting further UX priorities for those who were in non-management roles. 
          </p>

          <p>
          Wireframes and prototypes were done through the usage of Sketch and JustInMind.
          </p>
          
          
          <ul className="asset-list flex-row">
            <li>
              <a className={`zoom-in asset-standard`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1558968907/finL-3_yhosej.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1558968907/finL-3_yhosej.png" ></img></a>
            </li>   
            <li>
              <a className={`zoom-in asset-standard`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1558633236/finL-1_tahrwr.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1558633236/finL-1_tahrwr.png" ></img></a>
            </li>   
            <li>
              <a className={`zoom-in asset-standard`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1558633236/finL-2_xjwe5a.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1558633236/finL-2_xjwe5a.png" ></img></a>
            </li>  
          </ul>
        
          <h3>Iteration</h3>
          <p>Created iterations of wireframes after discussing and collaborating with the Requirements and Development teams.  Fidelity was increased to account for new expectations/requests for it to be included in a sales deck.</p>

          <ul className="asset-list flex-row">
            <li>
              <a className={`zoom-in asset-standard`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1583173090/fin-7_demnkx.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1583173090/fin-7_demnkx.png" ></img></a>
            </li>   
            <li>
              <a className={`zoom-in asset-standard`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1583172880/fin-8_xumpqq.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1583172880/fin-8_xumpqq.png" ></img></a>
            </li>   
          </ul>

          <p>
          Began the creation of a document to identify quick themes and insights through affinity mapping. Three main data categories were utilized: feedback by functionality, feedback by role, and feedback by page and theme.
          </p>

          <p>
          Provided insights and collaborated with a UX Architect to structure a deck on design thinking. Created for executive management, it served as the basis for extending deliverables.
          </p>

          <ul className="asset-list flex-row">
            <li>
              <a className={`zoom-in asset-standard`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1558968501/Fin-byFunctionality_csaet0.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1558968501/Fin-byFunctionality_csaet0.png" ></img></a>
            </li>   
            <li>
              <a className={`zoom-in asset-standard`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1558968501/Fin-bySectionAndTheme_jmjey0.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1558968501/Fin-bySectionAndTheme_jmjey0.png" ></img></a>
            </li>   
            <li>
              <a className={`zoom-in asset-standard`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1558968501/Fin-byRole_u5imi5.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1558968501/Fin-byRole_u5imi5.png" ></img></a>
            </li>   
          </ul>

          <h3>Conclusion</h3>
          
          <p>
          While comfortable in wireframing and prototyping, the experience brought to attention the need for formal UX training to better instill confidence for myself and the client. The experience also helped me further my interest and skills in data analysis.
          </p>
        </section>
        <div className="project-bottom">
          <Back></Back>
          <Link to="/react-storybook" title="To Next Project">
            Next Project
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default GovFinancialApp;